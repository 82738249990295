<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">TICKET TYPES</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-table
      :data="dataTypes"
      style="width: 100%">
      <el-table-column
        label="Name">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.name"></el-input>
            </template>
      </el-table-column>

      <el-table-column
        label="Description">
            <template #default="scope">
              <el-input size="small"
                type="textarea"
                style="text-align:center"
                v-model="scope.row.description"></el-input>
            </template>
      </el-table-column>

      <el-table-column
        label="Default Selection">
            <template #default="scope">
              <el-switch v-model="scope.row.isDefault"></el-switch>
            </template>
      </el-table-column>
      <el-table-column
        label="Is Special">
            <template #default="scope">
              <el-switch v-model="scope.row.isSpecial"></el-switch>
            </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">

            <div v-if="scope.$index >= types.length">
                <el-button
                 @click.native.prevent="createType(scope.row)" type="text" size="small">
                    Create
                </el-button>
            </div>
            <div class="flex gaps" v-else>
                <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                    Save
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                    Delete
                </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>
      <div class="flex justify-flex-end m-10">
        <el-button @click="addRow()" size="small">
            Add Type
        </el-button>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Types',

  data () {
    return {
      dataTypes: []
    }
  },

  computed: {
    ...mapState({
      types: state => state.type.types
    })
  },

  methods: {
    deleteRow (row, index) {
      this.$store.dispatch('removeType', row._id)
        .then(() => {
          this.dataTypes.splice(index, 1)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Type removed successfully',
            type: 'success'
          })
        })
    },
    saveRow (row) {
      this.$store.dispatch('editType', {
        id: row._id,
        content: row
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Type edited successfully',
            type: 'success'
          })
        })
    },
    createType (payload) {
      this.$store.dispatch('createType', payload)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Type created successfully',
            type: 'success'
          })
        })
    },
    addRow () {
      let newRow = {
        name: '',
        description: '',
        isSpecial: false,
        isDefault: false
      }
      this.dataTypes = [...this.dataTypes, newRow]
    }
  },
  mounted () {
    this.dataTypes = this.types.map((x) => Object.assign({}, x))
  }
}
</script>

<style lang="scss">
</style>
